<div class="container">
  <div class="section-title">
    <h2>Contact Us</h2>
    <div class="bar"></div>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse
      ultrices gravida.
    </p>
  </div>

  <div class="row align-items-center">
    <div class="col-lg-6">
      <div class="contact-form">
        <form id="contactForm">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <input
                  type="text"
                  name="name"
                  id="name"
                  class="form-control"
                  required
                  placeholder="Name"
                />
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input
                  type="email"
                  name="email"
                  id="email"
                  class="form-control"
                  required
                  placeholder="Email"
                />
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  name="phone_number"
                  id="phone_number"
                  required
                  class="form-control"
                  placeholder="Phone Number"
                />
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <textarea
                  name="message"
                  class="form-control"
                  id="message"
                  cols="30"
                  rows="6"
                  required
                  placeholder="Message"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="send-btn">
                <button type="submit" class="default-btn">
                  Send Message <span></span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="contact-info">
        <div class="contact-info-content">
          <h3>Contact with us by Your Phone Number or Email Address</h3>
          <h2>
            <a href="tel:+1-485-456-0102">+91 7259130123</a>
            <span>Or</span>
            <a href="mailto:hello@apzie.com">info@apexracing.in</a>
          </h2>
          <ul class="social">
            <li>
              <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
