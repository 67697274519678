<div class="container">
  <div class="section-title">
    <h2>Testimonials</h2>
    <div class="bar"></div>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse
      ultrices gravida.
    </p>
  </div>

  <div class="testimonials-slides">
    <div class="client-feedback">
      <div>
        <div class="item">
          <div class="single-feedback">
            <div class="icon">
              <i class="fa fa-quote-left"></i>
            </div>
            <p>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum
              suspendisse ultrices gravida.”
            </p>
          </div>
        </div>

        <div class="item">
          <div class="single-feedback">
            <div class="icon">
              <i class="fa fa-quote-left"></i>
            </div>
            <p>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum
              suspendisse ultrices gravida.”
            </p>
          </div>
        </div>

        <div class="item">
          <div class="single-feedback">
            <div class="icon">
              <i class="fa fa-quote-left"></i>
            </div>
            <p>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum
              suspendisse ultrices gravida.”
            </p>
          </div>
        </div>

        <div class="item">
          <div class="single-feedback">
            <div class="icon">
              <i class="fa fa-quote-left"></i>
            </div>
            <p>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum
              suspendisse ultrices gravida.”
            </p>
          </div>
        </div>

        <div class="item">
          <div class="single-feedback">
            <div class="icon">
              <i class="fa fa-quote-left"></i>
            </div>
            <p>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum
              suspendisse ultrices gravida.”
            </p>
          </div>
        </div>

        <div class="item">
          <div class="single-feedback">
            <div class="icon">
              <i class="fa fa-quote-left"></i>
            </div>
            <p>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum
              suspendisse ultrices gravida.”
            </p>
          </div>
        </div>

        <div class="item">
          <div class="single-feedback">
            <div class="icon">
              <i class="fa fa-quote-left"></i>
            </div>
            <p>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum
              suspendisse ultrices gravida.”
            </p>
          </div>
        </div>

        <div class="item">
          <div class="single-feedback">
            <div class="icon">
              <i class="fa fa-quote-left"></i>
            </div>
            <p>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum
              suspendisse ultrices gravida.”
            </p>
          </div>
        </div>
      </div>

      <button class="prev-arrow slick-arrow">
        <i class="fa fa-chevron-left"></i>
      </button>

      <button class="next-arrow slick-arrow">
        <i class="fa fa-chevron-right"></i>
      </button>
    </div>

    <div class="client-thumbnails">
      <div>
        <div class="item">
          <div class="img-fill">
            <img src="assets/img/client/1.jpg" alt="client" />
          </div>

          <div class="title">
            <h3>Reviewer Name 1</h3>
            <span>Apex Racing S3 E4</span>
          </div>
        </div>

        <div class="item">
          <div class="img-fill">
            <img src="assets/img/client/2.jpg" alt="client" />
          </div>

          <div class="title">
            <h3>Reviewer Name 2</h3>
            <span>Apex Racing S3 E4</span>
          </div>
        </div>

        <div class="item">
          <div class="img-fill">
            <img src="assets/img/client/3.jpg" alt="client" />
          </div>

          <div class="title">
            <h3>Reviewer Name 3</h3>
            <span>Apex Racing S3 E4</span>
          </div>
        </div>

        <div class="item">
          <div class="img-fill">
            <img src="assets/img/client/4.jpg" alt="client" />
          </div>

          <div class="title">
            <h3>Reviewer Name 4</h3>
            <span>Apex Racing S3 E4</span>
          </div>
        </div>

        <div class="item">
          <div class="img-fill">
            <img src="assets/img/client/5.jpg" alt="client" />
          </div>

          <div class="title">
            <h3>Reviewer Name 5</h3>
            <span>Apex Racing S3 E4</span>
          </div>
        </div>

        <div class="item">
          <div class="img-fill">
            <img src="assets/img/client/1.jpg" alt="client" />
          </div>

          <div class="title">
            <h3>Reviewer Name 5</h3>
            <span>Apex Racing S3 E4</span>
          </div>
        </div>

        <div class="item">
          <div class="img-fill">
            <img src="assets/img/client/2.jpg" alt="client" />
          </div>

          <div class="title">
            <h3>Reviewer Name 6</h3>
            <span>Apex Racing S3 E4</span>
          </div>
        </div>

        <div class="item">
          <div class="img-fill">
            <img src="assets/img/client/3.jpg" alt="client" />
          </div>

          <div class="title">
            <h3>Reviewer Name 7</h3>
            <span>Apex Racing S3 E4</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
