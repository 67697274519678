import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-upcoming-sessions',
  templateUrl: './landing-upcoming-sessions.component.html',
  styleUrls: ['./landing-upcoming-sessions.component.scss']
})
export class LandingUpcomingSessionsComponent {

}
