import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-contact',
  templateUrl: './landing-contact.component.html',
  styleUrls: ['./landing-contact.component.scss']
})
export class LandingContactComponent {

}
