<section class="footer-area pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-sm-6">
        <div class="single-footer-widget">
          <a routerLink="/" class="logo"><h2>Apex Racing Academy</h2></a>
          <p>
            Join India's top racing academy and become a champion on the track. Learn from expert instructors and experience the thrill of speed.
          </p>
          <ul class="social-list">
            <li>
              <a href="https://www.facebook.com/apexracingacademy" target="_blank"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a>
            </li>
            <li>
              <a href="https://www.instagram.com/apex_racing_academy/" target="_blank"><i class="fab fa-instagram"></i></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="single-footer-widget pl-5">
          <h3>Company</h3>

          <ul class="list">
            <li><a routerLink="/">About</a></li>
            <li><a routerLink="/">Upcoming Events</a></li>
            <!-- <li><a routerLink="/">User Program</a></li> -->
            <!-- <li><a routerLink="/">Apex Racing Academy</a></li> -->
            <!-- <li><a routerLink="/">Merchendise</a></li> -->
            <li><a routerLink="/contact">Contact</a></li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="single-footer-widget">
          <h3>Documentations</h3>

          <ul class="list">
            <li><a routerLink="/terms-of-service">Terms of Service</a></li>
            <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="go-top">
  <i class="fa fa-chevron-up"></i>
  <i class="fa fa-chevron-up"></i>
</div>
