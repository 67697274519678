import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-blog',
  templateUrl: './landing-blog.component.html',
  styleUrls: ['./landing-blog.component.scss']
})
export class LandingBlogComponent {

}
