<div class="container">
  <div class="section-title">
    <h2>Why Choose Apex Racing Academy?</h2>
    <div class="bar"></div>
    <p>
      Joining Apex Racing Academy guarantees personalized, expert-guided training to elevate your riding skills on both
      the street and the race track, ensuring you become a safer, more confident, and proficient rider.
    </p>
  </div>

  <div class="row">
    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <i class="fa fa-sun"></i>
        </div>
        <h3>Expert Guidance</h3>
        <p>
          Apex Racing Academy provides expert instruction from experienced riders who have honed their skills on both
          the street and race track.
        </p>
      </div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <i class="fa fa-motorcycle"></i>
        </div>
        <h3>Customized Training</h3>
        <p>
          The academy tailors training programs to suit individual riders, ensuring personalized attention and focused
          improvement.
        </p>
      </div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <i class="fa fa-wand-magic-sparkles"></i>
        </div>
        <h3>Advanced Techniques</h3>
        <p>
          Gain mastery over advanced riding techniques, equipping you to handle a variety of situations on both the
          street and the track.
        </p>
      </div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <i class="fa fa-shield"></i>
        </div>
        <h3>Safety Emphasis</h3>
        <p>
          Safety is paramount at Apex Racing Academy. Learn crucial safety practices that enhance your confidence and
          competence as a rider.
        </p>
      </div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <i class="fa fa-house-crack"></i>
        </div>
        <h3>Track Access</h3>
        <p>
          Access to premium tracks gives you the opportunity to practice in a controlled environment, refining your
          skills under professional supervision.
        </p>
      </div>
    </div>

    <div class="col-lg-4 col-md-6">
      <div class="single-features">
        <div class="icon">
          <i class="fa fa-face-smile"></i>
        </div>
        <h3>Community and Networking</h3>
        <p>
          Joining the Apex Racing Academy connects you with a community of like-minded riders, providing valuable
          networking opportunities and a supportive environment.
        </p>
      </div>
    </div>
  </div>
</div>