import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-expert-team',
  templateUrl: './landing-expert-team.component.html',
  styleUrls: ['./landing-expert-team.component.scss']
})
export class LandingExpertTeamComponent {

}
