<div class="container">
  <div class="row align-items-center">
    <div class="col-lg-3 col-sm-6">
      <div class="single-fun-fact">
        <div class="icon">
          <i class="fa fa-heart"></i>
        </div>
        <h3><span>10</span></h3>
        <p>Seasons</p>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6">
      <div class="single-fun-fact">
        <div class="icon">
          <i class="fa fa-heart"></i>
        </div>
        <h3><span>100</span></h3>
        <p>Editions</p>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6">
      <div class="single-fun-fact">
        <div class="icon">
          <i class="fa fa-trophy"></i>
        </div>
        <h3><span>10</span></h3>
        <p>National Champions</p>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6">
      <div class="single-fun-fact">
        <div class="icon">
          <i class="fa fa-users"></i>
        </div>
        <h3><span>5000</span></h3>
        <p>Students</p>
      </div>
    </div>
  </div>
</div>
