<div class="container">
  <div class="section-title">
    <h2>About Apex Racing Academy</h2>
    <div class="bar"></div>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse
      ultrices gravida.
    </p>
  </div>

  <div class="row align-items-center">
    <div class="col-lg-6 col-md-12">
      <div class="about-content">
        <h3>Our process is simple, Our Method is powerful</h3>
        <div class="bar"></div>
        <p>
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly believable.
        </p>
        <p>
          If you are going to use a passage of Lorem Ipsum, you need to be sure
          there isn't anything embarrassing hidden in the middle of text. All
          the Lorem Ipsum generators on the Internet tend to repeat predefined
          chunks as necessary, making this the first true generator on the
          Internet.
        </p>
        <div class="about-btn">
          <a routerLink="/" class="default-btn">Read More <span></span></a>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-12">
      <div class="about-image">
        <img src="https://placehold.jp/700x700.png" alt="image" />
      </div>
    </div>
  </div>
</div>
