import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-features',
  templateUrl: './landing-features.component.html',
  styleUrls: ['./landing-features.component.scss']
})
export class LandingFeaturesComponent {

}
