import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-stats',
  templateUrl: './landing-stats.component.html',
  styleUrls: ['./landing-stats.component.scss']
})
export class LandingStatsComponent {

}
