import { Component } from '@angular/core';

@Component({
  selector: 'app-racing-team',
  templateUrl: './racing-team.component.html',
  styleUrls: ['./racing-team.component.scss']
})
export class RacingTeamComponent {

}
